import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Data, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import Keycloak from 'keycloak-js';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { MykeycloakService } from './mykeycloak.service';
import { UserKeycloakDtoNBK } from '../api/nbk';

export interface KeyCloackUserCheckCompleted {
  completed: Array<boolean>;
}
export interface KeyCloackUserExtended extends Keycloak.KeycloakProfile {
  attributes?: KeyCloackUserCheckCompleted;
}

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {
  private userIsCompleted: boolean = false;
  private keyCloackUserExtended: KeyCloackUserExtended;

  constructor(
    private myKeycloakService: MykeycloakService,
    private router: Router,
    private authService: AuthService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.handleAuthentication(route.data);
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.handleAuthentication(childRoute.data);
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.handleAuthentication(route.data);
  }

  private async handleAuthentication(data?: Data): Promise<boolean | UrlTree> {
    this.checkUserIsCompleted();

    if ( !this.userIsCompleted ) {
      return this.router.createUrlTree(['user-profile']);
    }

    if (data && data.permission) {
      const hasPermission = data.permission.some((role: UserKeycloakDtoNBK.RolesEnum) => this.authService.permissions[role]);
      return hasPermission || this.redirectUser();
    }
    
    return true;

  }

  private checkUserIsCompleted() {
    this.keyCloackUserExtended = this.myKeycloakService.user;
    const completed = new Boolean(this.keyCloackUserExtended?.attributes?.completed?.[0]);

    this.userIsCompleted = this.authService.permissions.SERVICE || this.authService.permissions.USER_MANAGER ||
      completed.valueOf() === true;

  }

  private redirectUser() {
    if ( this.authService.permissions.USER_MANAGER ) {
      return this.router.createUrlTree(['user-roles']);
    } else if ( this.authService.permissions.SERVICE ) {
      return this.router.createUrlTree(['devices']);
    } else {
      return this.router.createUrlTree(['dashboard']);
    }

  }


}
